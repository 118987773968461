.aboutUs{
    padding: 120px ;
    background-color: #4154f1;
    position: relative;
    top: 84px;
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
}
.about-section{
    padding-top: 200px;
    padding-bottom: 150px;
}
.about-section h3{
    color: white;
}
.about-section h1{
    font-size: 40px;
    font-weight: 800;
    color: white;
}
.main-about-section{
    background: linear-gradient( 0deg, rgb(31,53,219) 0%, rgb(72,167,243) 100%);
}
.user-pic {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 100%;
    margin: 20px auto 20px;
    border-left: 3px solid #ddd;
    border-right: 3px solid #ddd;
    border-top: 3px solid #007bff;
    border-bottom: 3px solid #007bff;
    transform: rotate(-30deg);
    transition: 0.5s;
}
.card-box:hover .user-pic {
    transform: rotate(0deg);
    transform: scale(1.1);
}
.card-box {
    padding: 15px;
    background-color: #fdfdfd;
    margin: 20px 0px;
    border-radius: 10px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 8px 0px #d4d4d4;
    transition: 0.5s;
}
.card-box:hover {
    border: 1px solid #007bff;
}
.card-box p {
    color: #808080;
}
.expert-team h1{
    padding-top: 60px;
    font-size: 30px;
    font-weight: 700;
}
.expert-team p{
    font-size: 17px;
}
.main-card h4{
    font-size: 18px;
    font-weight: 500;
}
.main-card h6{
    font-size: 12px;
}
.main-card p{
    font-size: 12px;
}
.section-head h2{
    color: black;
    font-size: 3rem!important;
    font-weight: 800;
    padding-bottom: 10px;
    padding-top: 40px;
}
.section-head p{
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin-bottom: 20px;
    color: gray;
}
.main-card-section h5{
    font-size: 20px;
}
.card:hover{
    transform: translateY(-5px);
    box-shadow: 0 10px 50px #9166f5;
}
.main-head{
    color: black;
    font-size: 2rem!important;
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
}
.main-desc{
    font-size: 16px!important;
    letter-spacing: 0.6px;
    line-height: 1.5em;
    margin-bottom: 25px;
    text-align: center;
    /* padding-right: 35px; */
    font-family: sans-serif;
    color: gray;
}
.fifth-section{
    padding-top: 90px;
}
.fifth-section .card{
    background: linear-gradient( 0deg, rgb(31,53,219) 0%, rgb(72,167,243) 100%);
    border: none;
    color: white;
    padding: 20px;
}
.fifth-section .card h1{
    font-size: 31px;
    font-weight: 600;
    margin-bottom: 16px;
}
.fifth-section .card p{
    font-size: 18px;
    font-family: sans-serif;
}
.fifth-section .card button{
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 34px;
    font-size: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
    margin-top: 20px;
}
.card-body a{
    padding: 10px 20px;
    border-radius: 0px;
    display: block;
    font-size: 14px;
    background: linear-gradient(to right, #733ef9, #9166f5);
    color: white;
}
.card-body a:hover{
    color: white;
}
.main-card-section .card-body{
    height: 410px!important;
}
@media only screen and (max-width: 768px) {
    .section-head h2 {
        font-size: 45px!important;
        font-weight: 700!important;
    }
    .section-head p{
        font-size: 16px!important;
    }
    .main-card-section .card-body {
        height: 384px!important;
    }
  }

.fifth-section img{
    width:280px;
    height: 200px;
}